import { IonContent, IonPage } from '@ionic/react';
import { useState } from 'react';
import React from 'react'

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';

/* CSS */
import '../theme/global.css';
import RegisterPage from '../components/login/RegisterPage';
import Cookies from 'js-cookie';
import FalseRegister from './login/FalseRegister';
import RegisterComp from './login/RegisterComp';



const RegisterTemp: React.FC = (props) => {
  const [actualPage, setActualPage] = useState("login");

  return (
    <>
      <IonPage className="desktop">
        <HeaderEmpty />
        <IonContent fullscreen className="fullSize bg-gris-clar">          
          <RegisterComp setActualPage={setActualPage} />
        </IonContent>

      </IonPage>

      <IonPage className={`mvl ${Cookies.get('token')} this ${actualPage} that`}>
        <IonContent fullscreen className="fullSize">
          <RegisterComp setActualPage={setActualPage} />
        </IonContent>
      </IonPage>
    </>
  );

};

export default RegisterTemp;