import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import home from './img/iconos/home.svg';
import obj from './img/iconos/object.svg';
import activ from './img/iconos/activ.svg';
import resource from './img/iconos/resource.svg';
import companion from './img/iconos/companion.svg';
import Principal from './pages/Principal';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import Login from './pages/Login';
import EditarUsuario from './pages/EditarUsuario';
import Biblioteca from './pages/Biblioteca';
import OneSignalMvl from 'onesignal-cordova-plugin';
import ObjectiusMvl from './pages/MvlObjetivos';
import ActivitatsMvl from './pages/MvlActivitats';
import RecursosMvl from './pages/MvlRecursos';
import AcompañantesMvl from './pages/MvlAcompañantes';

import MvlBiblioRecurso from './pages/MvlBiblioRecurso';
import MvlBiblioActividad from './pages/MvlBiblioActividad';
import MvlBiblioObjetivo from './pages/MvlBiblioObjetivo';
import Admin from './pages/Admin';
import AdminHome from './pages/AdminHome';
import { isPlatform } from '@ionic/react';
import Cookies from 'js-cookie';
import ChangePassword from './pages/ChangePassword';
import Tab1 from './pages/Home';
import { OneSignalFunctions } from './hooks/OneSignalFunctions';
import { useState } from 'react';
import { useEffect } from 'react';
import Testing from './pages/Testing';
import RegisterTemp from './components/RegisterTemp';
import UltimaActividad from './components/UltimaActividad';
import UltimaActividadPage from './pages/UltimaActividadPage';





const App: React.FC = () => {
  const { getActiveState, getAllData } = OneSignalFunctions();
  const [ done, setDone ] = useState(false);


  useEffect(() => {
    let ignore = false;
    
    if (!ignore)  {
      //  {isPlatform('hybrid') 
      //   ? OneSignalInit()
      //   : OneSignalWPAInit() }
      // OneSignalWPAInit();
    }
    return () => { ignore = true; }
    },[]);
  
  const OneSignalInit = () => {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignalMvl.setAppId("1fd3aeb7-6b3d-4684-9352-56f69862d7b7");
    OneSignalMvl.setNotificationOpenedHandler(function (jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignalMvl.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
      if (!done) {
        getAllData();
      }
      setDone(true);
    });
  }

  const OneSignalWPAInit = () =>  {
    //@ts-ignore
    window.OneSignal = window.OneSignal || [];
    //@ts-ignore
    OneSignal.push(function() {
      //@ts-ignore

      //Producció
      OneSignal.init({
        appId: "1fd3aeb7-6b3d-4684-9352-56f69862d7b7",
        safari_web_id: "web.onesignal.auto.2358eea7-3e97-4fb7-b492-b25c712616ac",
        notifyButton: {
          enable: true,
        },
        subdomainName: "comunitecaa",
      });
    });
  }


 

  return (
    <>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/test" component={Testing} />
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>

               <Route path="/registreTemporal" component={RegisterTemp} />
              <Route path="/login" component={Login} />
               <Route path="/control" component={Admin} />
              <Route path="/admin" component={AdminHome} />

              <Route path="/principal" component={Principal} />
              <Route path="/principal/ultimaactividad" component={UltimaActividadPage} />

              <Route path="/biblioteca" component={Biblioteca} />

              <Route path="/biblioteca/recursos" component={MvlBiblioRecurso}/>
              <Route path="/changePassword/:token" component={ChangePassword}/>

              <Route path="/biblioteca/actividades">
                <MvlBiblioActividad page="biblActividad"/>
              </Route>
              <Route path="/biblioteca/objetivos">
                <MvlBiblioObjetivo page="biblObjetivo"/>
              </Route>

              <Route path="/home" component={Tab1}/>

              <Route path="/objetivos">
                <ObjectiusMvl />
              </Route>
              <Route path="/actividades">
                <ActivitatsMvl />
              </Route>
              <Route path="/recursos">
                <RecursosMvl />
              </Route>
              <Route path="/acompañantes">
                <AcompañantesMvl />
              </Route>
              <Route path='/editarusuario/:id' component={EditarUsuario} />

            </IonRouterOutlet>


            <IonTabBar slot="bottom" className="mvl" id="tabBar">
              <IonTabButton tab="home" className="tab-home tab-app" href="/home">
                <IonIcon src={home} />
              </IonTabButton>

              <IonTabButton tab="objetivos" className="tab-objetivos tab-app" href="/objetivos">
                <IonIcon src={obj} />
              </IonTabButton>

              <IonTabButton tab="actividades" className="tab-actividades tab-app" href="/actividades">
                <IonIcon src={activ} />
              </IonTabButton>

              <IonTabButton tab="recursos" className="tab-recursos tab-app" href="/recursos">
                <IonIcon src={resource} />
              </IonTabButton>

              <IonTabButton tab="acompañantes" className="tab-acompañantes tab-app" href="/acompañantes" >
                <IonIcon src={companion} />
              </IonTabButton>


              <IonTabButton tab="objetivosBiblio" className="tab-biblio tab-biblio-objetivo" href="/biblioteca/objetivos">
                <IonIcon src={obj} />
              </IonTabButton>

              <IonTabButton tab="actividadesBiblio" className="tab-biblio tab-biblio-actividad" href="/biblioteca/actividades">
                <IonIcon src={activ} />
              </IonTabButton>

              <IonTabButton tab="recursosBiblio" className="tab-biblio tab-biblio-recursos" href="/biblioteca/recursos">
                <IonIcon src={resource} />
              </IonTabButton>
            </IonTabBar>


          </IonTabs>


        </IonReactRouter>
      </IonApp>
    </>
  )
};

export default App;


