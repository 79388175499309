import { IonRow, IonCol, IonContent, IonPage, IonInput } from '@ionic/react';
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getData } from '../api/apiRecursos';

/* COMPONENTES */


/* CSS */
import '../theme/custom.css';




const Admin: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [contraseña, setContraseña] = useState<string>("");
  const axios = require('axios');
  let history = useHistory();

  const tryLogin = () => {

    const loginData = {
      email: username,
      password: contraseña,
    };
    

    if (username != "" && contraseña != "") {
      getData.post("/loginAdmin", loginData)
      .then((res: any) => {
        if (res.status === 200) {
          Cookies.set('tokenAdmin', res.data.token, { sameSite: 'strict' });
          history.push("/admin");
        } else {
          // alert("El mail/contraseña no coincide o el usuario no existe. Vuelve a intentarlo por favor.");
        }
      })
      .catch((error: any) => {
      });
    }

    
  }

  return (
    <>
      <IonPage>
        <IonContent fullscreen className="fullSize bg-gris-clar">

          

          <IonRow style={{ 'height': '100vh' }}>
            <IonCol size='4' class='mx-auto align-self-center'>
              <div className='cnt-admin-login'>
                <div>
                  <div className="form-group">
                    <label className="label">Nombre de usuario</label>
                    <div className="input-group">
                      <IonInput type="text" className="form-control" name="username" placeholder="username" onIonChange={e => {setUsername(e.detail.value?.toString()!);}}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label">Password</label>
                    <div className="input-group">
                      <IonInput type="password" name="password" className="form-control" placeholder="*********" onIonChange={e => {setContraseña(e.detail.value?.toString()!);}}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary submit-btn btn-block w-100" onClick={e => tryLogin()}>Entrar</button>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>



        </IonContent>


      </IonPage>
    </>
  );

};

export default Admin;
