import { useState, useEffect } from "react";
import { isPlatform } from '@ionic/react';


import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'
import { Capacitor } from '@capacitor/core';
import axios from "axios";

const PHOTO_STORAGE = "photos";
export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [lastPhoto, setLastPhoto] = useState<UserPhoto>();
  const [filePhoto, setFilePhoto] = useState<File>();

  useEffect(() => {
    const loadSaved = async () => {
      const {value} = await Storage.get({key: PHOTO_STORAGE });

      const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];
      // If running on the web...
      if (!isPlatform('hybrid')) {
        for (let photo of photosInStorage) {
          const file = await Filesystem.readFile({
            path: photo.filepath,
            directory: Directory.Data
          });
          // Web platform only: Load the photo as base64 data
          photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
        }
      }
      setPhotos(photosInStorage);
    };
    loadSaved();
  }, []);

  const takePhoto = async () => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 70,
      width:1500,
      height:1500
    });
    const fileName = new Date().getTime() + '.jpeg';
    const savedFileImage = await savePicture(cameraPhoto, fileName);
    const newPhotos = [savedFileImage, ...photos];
    setLastPhoto(savedFileImage);
    convertToFile(savedFileImage);
    setPhotos(newPhotos);
  };
  

  const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
    let base64Data: string | Blob;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path!
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data
    });
    if (isPlatform('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
      };
    }
    else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath
      };
    }
  };

  const deletePhoto = async (photo: UserPhoto) => {
    // Remove this photo from the Photos reference data array
    const newPhotos = photos.filter(p => p.filepath !== photo.filepath);

    // Update photos array cache by overwriting the existing photo array
    Storage.set({key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });

    // delete photo file from filesystem
    const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
    await Filesystem.deleteFile({
      path: filename,
      directory: Directory.Data
    });
    setPhotos(newPhotos);
  };

  const convertToFile = (lastPhoto: UserPhoto) => {
    axios({
      method: 'get',
      url: lastPhoto.webviewPath, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
      responseType: 'blob'
    }).then(function (response: { data: Blob; }) {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function () {
        // base64data = ;
        convertBase64ToBlob(reader.result)
        .then( function (thisFile) {
           setFilePhoto(thisFile); 
        })        
      }
    })
  }

  return {
    deletePhoto,
    photos,
    takePhoto,
    lastPhoto,
    setLastPhoto,
    filePhoto
  };
}

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

const convertBase64ToBlob = (base64data: any) => {
  //@ts-ignore
  var mime = base64data.match(/:(.*?);/)[1];
  var extension:string;
  if (mime == 'image/svg+xml')
  {
    extension = 'svg';
  } else {
    extension = mime.split('/')[1];
  }
  return (fetch(base64data)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], Date.now()+'.'+extension,{type:mime});})
      );
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string')
      }
    };
    reader.readAsDataURL(blob);
  });
}