import { IonAvatar, IonCol, IonGrid, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading, IonPopover, IonRow, IonSkeletonText, useIonViewWillEnter } from "@ionic/react";
import $ from 'jquery';

/* COMPONENTES */
import NuevaPublicacion from "./NuevaPublicacion";
import NuevoComentario from "./NuevoComentario";

/* ICONOS */
import ChinchetaBlack from "../img/iconos/chincheta_black.svg"
import DianaBlack from "../img/iconos/diana_black.svg"
import ChinchetaWhite from "../img/iconos/chincheta_white.svg"
import ChinchetaOrange from "../img/iconos/chincheta_orange.svg"
import ChinchetaTransp from "../img/iconos/chincheta_transp.svg"
import DianaWhite from "../img/iconos/diana_white.svg"
import objetivosLogo from '../img/iconos/objetivos-logo.svg';
import actividadesLogo from '../img/iconos/actividades-logo.svg';
import recursosLogo from '../img/iconos/recursos-logo.svg';
import punts from '../img/iconos/punts.svg';
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';

import actividad_final from '../img/iconos/logros/Actividad_final.svg';
import actividad_inicio from '../img/iconos/logros/Actividad_inicio.svg';
import obj_inicio from '../img/iconos/logros/Obj_0.svg';
import obj_25 from '../img/iconos/logros/Obj_25.svg';
import obj_50 from '../img/iconos/logros/Obj_50.svg';
import obj_75 from '../img/iconos/logros/Obj_75.svg';
import obj_100 from '../img/iconos/logros/Obj_100.svg';
import recurso from '../img/iconos/logros/Recurso_nuevo.svg';

import actividad_final_mvl from '../img/iconos/logros/Actividad_final-mvl.svg';
import actividad_inicio_mvl from '../img/iconos/logros/Actividad_inicio-mvl.svg';
import obj_inicio_mvl from '../img/iconos/logros/Obj_0-mvl.svg';
import obj_25_mvl from '../img/iconos/logros/Obj_25-mvl.svg';
import obj_50_mvl from '../img/iconos/logros/Obj_50-mvl.svg';
import obj_75_mvl from '../img/iconos/logros/Obj_75-mvl.svg';
import obj_100_mvl from '../img/iconos/logros/Obj_100-mvl.svg';
import recurso_mvl from '../img/iconos/logros/Recurso_nuevo-mvl.svg';

// JSON
import { Fragment, useEffect, useState } from "react";
import { Post } from "../interfaces/intPosts";
import { getData } from "../api/apiRecursos";
import Cookies from "js-cookie";
import Comentario from "./Comentario";
import logoEmptyTimeline from "../img/iconos/logo_empty_timeline.svg"
import { GlobalFunctions } from "../hooks/GlobalFunctions";
import BigPhotoPopUp from "./PopUpModals/BigPhotoPopUp";

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setUserRole} from '../features/groups/groupSelectedSlice';
import { post } from "jquery";
import { Virtuoso } from "react-virtuoso";
import { GlobalVariables } from "../hooks/GlobalVariables";

interface ContainerProps {
  setActualPage: any, 
  setLastAction: any,
  lastAction: string,
  setSelectedId: any,
  setShowAlert: any,
  textAlert: string,
  setTextAlert: any,
  showAlert: boolean
}

const Home: React.FC<ContainerProps> = ({ setSelectedId, setActualPage, setLastAction, lastAction }) => {
   //REDUX 
   const posts = useAppSelector(state=>state.reducer.groupSelected.posts);
   const role = useAppSelector(state=>state.reducer.groupSelected.userRole)

  const {showMedia, urlify, getPostsGroupSelected, removePost} = GlobalFunctions();
  const [showDestacados, setDestacados] = useState(false);
  const [showObjetivos, setObjetivos] = useState(false);
  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });
  const [editPost, setEditPost] = useState(0);
  const [showPhoto2, setShowPhoto2] = useState(false);
  const [postLimit, setPostLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const {urlMedia, urlApi} = GlobalVariables();
  const [refresh, setRefresh] = useState(true);

  const axios = require('axios');
  const api = axios.create({
    baseURL: urlApi
  });


  const isDestacado = () => {
    setDestacados(!showDestacados);
    if (showObjetivos) {
      setObjetivos(!showObjetivos);
    }
  }
  const isObjetivo = () => {
    setObjetivos(!showObjetivos);
    if (showDestacados) {
      setDestacados(!showDestacados);
    }
  }
  const changeFixedStatus = (id: number) => {
    const userData = {
      token: Cookies.get('token'),
      id: id,
    }

    api.post("/changeFixed", userData)
      .then(() => {
        getPostsGroupSelected(undefined);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }
  const erasePost = (id: number) => {
    setLoading(true);
    removePost(id, setLastAction, setShowPopoverNuevo, setLoading);
  }
  const showDate = (date: Date) => {
    let now = Date.now();
    let postedTime = new Date(date);
    let elapsedTime = now - postedTime.getTime();
    let minutes = Math.floor(elapsedTime / 60000) - 59;
    const month = postedTime.toLocaleString('default', { month: 'long' });
    if (minutes > 60) {
      return `${postedTime.getDate()} de ${month} de ${postedTime.getFullYear()} - ${postedTime.getHours()}:${String(postedTime.getMinutes()).padStart(2,'0')}`;
    } else {
      return `Hace ${minutes} minutos`;
    }

  }
  const showIcon = (important: number) => {
    if (important == 1) {
      return ChinchetaOrange;
    } else {
      if (role?.role! > 1) {
        return ChinchetaTransp;
      } else {
        return "";
      }
    }
  }
  const showFilter = () => {
    let boolImportant = posts.find(publi => publi.important == 1);
    let boolObjective = posts.find(publi => publi.type == 'objective-achievement');


    return (
      <>
        {boolImportant || boolObjective
          ?
          <div className="row">
            <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
              <p className="body2">Filtrar</p>
              {boolImportant
                ?
                <button onClick={() => isDestacado()}>
                  <div className={`btn-filter-home ${showDestacados ? "selected" : ""}`}>
                    <IonIcon className="grande" src={showDestacados ? ChinchetaWhite : ChinchetaBlack}></IonIcon>
                  </div >
                </button>
                : null}
              {boolObjective ?
                <button onClick={() => isObjetivo()}>
                  <div className={`btn-filter-home ${showObjetivos ? "selected" : ""}`}>
                    <IonIcon className="grande" src={showObjetivos ? DianaWhite : DianaBlack}></IonIcon>
                  </div>
                </button>
                : null
              }
            </div>
          </div>

          : null
        }

      </>
    )
  }
  const mostraImatge = (avatar: string, type: string) => {
    if (type === "post" || type === "post-user") {
      return (<IonAvatar className='d-flex'>
        <img className={`photo m-auto avatar-small ${avatar != null ? 'border-gris' : null}`} src={avatar != null ? urlMedia + avatar : noPhotoAvatar}></img>
      </IonAvatar>)
    } else {
      switch (type) {
        case "resource-achievement":
          return <IonAvatar className='d-flex'><img className="avatar-small m-auto " src={recursosLogo}></img></IonAvatar>

        case "activity-achievement":
          return <IonAvatar className='d-flex'><img className="avatar-small m-auto" src={actividadesLogo}></img></IonAvatar>

        case "objective-achievement":
          return <IonAvatar className='d-flex'>
            <img className="avatar-small m-auto" src={objetivosLogo}></img></IonAvatar>

        default:
          break;
      }
    }

  }
  const showElement = (type: string, id: number) => {
    setSelectedId(id);
    switch (type) {
      case 'activity-achievement':
        setActualPage('activityDetail')
        break;
      case 'objective-achievement':
        setActualPage('objectiveDetail')
        break;
      case 'resource-achievement':
        setActualPage('resourceDetail')
        break;
      default:
        break;
    }
  }
  const showAchievementLogo = (type: string, value: number) => {
    switch (type) {
      case 'resource-achievement':
        return <><IonImg className="achievement-logo-width desktop" src={recurso} />
        <IonImg className="achievement-logo-width mvl" src={recurso_mvl} /></>;
      case 'activity-achievement':
        if (value == 0) {
          return <><IonImg className="achievement-logo-width desktop" src={actividad_inicio} />
          <IonImg className="achievement-logo-width mvl" src={actividad_inicio_mvl} /></>;
        } else {
          return <><IonImg className="achievement-logo-width desktop" src={actividad_final} />
          <IonImg className="achievement-logo-width mvl" src={actividad_final_mvl} /></>;
        }
      case 'objective-achievement':
        if (value == 0) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_inicio} />
          <IonImg className="achievement-logo-width mvl" src={obj_inicio_mvl} /></>;
        } else if (value == 25) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_25} />
          <IonImg className="achievement-logo-width mvl" src={obj_25_mvl} /></>;
        } else if (value == 50) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_50} />
          <IonImg className="achievement-logo-width mvl" src={obj_50_mvl} /></>;
        } else if (value == 75) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_75} />
          <IonImg className="achievement-logo-width mvl" src={obj_75_mvl} /></>;
        } else {
          return <><IonImg className="achievement-logo-width desktop" src={obj_100} />
          <IonImg className="achievement-logo-width mvl" src={obj_100_mvl} /></>;
        }
      default:
        return null;
    }

  }
  const loadData = () => {
    if (postLimit < posts.length) {
      $('.infinite-loading').addClass('d-block');
    $('.infinite-loading').removeClass('d-none');
    }
    
    setTimeout(() => {
      setPostLimit(postLimit+10);
      if (postLimit >= posts.length) {
      }
      $('.infinite-loading').addClass('d-none');
      $('.infinite-loading').removeClass('d-block');
    }, 500);
  } 

  const handleScroll = (event: any) => {
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    const clientHeight = event.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + 200) <= clientHeight) {
      loadData();
    }
  };


  const showPost = (publicacion: Post, index: number) => {
    return (
      <IonGrid key={index} className={`publicacion mb-5 ${publicacion.type}`}>
        {publicacion.type === "activity-achievement" || publicacion.type === "resource-achievement" || publicacion.type === "objective-achievement"
          ? <IonRow className={`m-0 achievement-logo ${publicacion.type}`} >
            <IonCol className="d-flex justify-content-center">
              {showAchievementLogo(publicacion.type, publicacion.value)}
            </IonCol>
          </IonRow>
          : null}
        <IonRow className="mx-3">
          <IonCol size="12" className="lineal-flex ion-align-items-center ion-justify-items-center">
            {mostraImatge(publicacion.user_media.media_name, publicacion.type)}
            <div>
              <p><b>{publicacion.name}</b></p>
              <p className="body2 text-gris" >{showDate(publicacion.created_at)}</p>
            </div>
            <div className="right-0">
            {publicacion.type === "post" || publicacion.type === "post-user" || publicacion.type === "activity-achievement" || publicacion.type === "resource-achievement" || publicacion.type === "objective-achievement"
              ? <><IonIcon className="chincheta-home handCursor bigger-hover" src={showIcon(publicacion.important!)}
                onClick={() => <>{role!.role > 1 ? changeFixedStatus(publicacion.id) : null}</>} />
                {role?.role == 3 || role?.user_id == publicacion.created_by ?
                  <>
                    <IonPopover
                        // cssClass='my-custom-class'
                        event={popoverStateNuevo.event}
                        isOpen={popoverStateNuevo.showPopover}
                        onDidDismiss={() => setShowPopoverNuevo({ showPopover: false, event: undefined })}              >
                    
                      <a className='popup-select' onClick={() => erasePost(editPost)}><p className="body1">Eliminar este mensaje</p></a>
                    </IonPopover>
                    <IonIcon className="medio handCursor" src={punts} onClick={
                      (e: any) => {
                        e.persist();
                        setEditPost(publicacion.id)
                        setShowPopoverNuevo({ showPopover: true, event: e })
                      }} >
                    </IonIcon>
                  </>
                  : null}
              </>
              : null}
            </div>
          </IonCol>
        </IonRow>
        {publicacion.content || publicacion.media_id
          ?
          <IonRow className="mx-3">
            <IonCol size="12">
              {publicacion.content
                ? <><p className={`body2 mx-4 
                  ${publicacion.type}`} dangerouslySetInnerHTML={{ __html: urlify(publicacion.content) }} onClick={() => <>
                    {publicacion.type == 'activity-achievement' ||
                    publicacion.type == 'objective-achievement' ||
                    publicacion.type == 'resource-achievement'
                      ? showElement(publicacion.type, publicacion.id_referencia)
                      : null}</>}></p> {console.log(publicacion.content)}</>
                : null}

              {publicacion.media_id
                ? <div className="d-flex justify-content-center">
                    {showMedia(publicacion.id, publicacion.media_name, publicacion.media_name?.split('.')[1], setShowPhoto2)}
                </div>
                : null}
            </IonCol>
          </IonRow>
          : null}
        <IonGrid className="container comentarios">
          {publicacion.comments != undefined
            ? publicacion.comments.map((comentario, index) =>
              <Comentario key={index} comentario={comentario}  setLastAction={setLastAction} />
            )
            : null}
        </IonGrid>
        <NuevoComentario idNuevoComentario={index} parentId={publicacion.id} setLastAction={setLastAction} />
      </IonGrid>
    )

  }

  
  return (
    <>
    <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={99999999}
      />
      <div className="container-right pb-0 scrollable-content" onScroll={handleScroll} >
        <div className="container">
          <div className="mb-5">
            <NuevaPublicacion setLastAction={setLastAction} lastAction={lastAction} isDestacado={isDestacado}/>
            {showFilter()}
          </div>

          {posts.filter(publicacion => publicacion.type === "post").length == 0 && 
            posts.filter(publicacion => publicacion.type === "post-user").length == 0 &&
            posts.filter(publicacion => publicacion.type === "objective-achievement").length == 0 &&
            posts.filter(publicacion => publicacion.type === "activity-achievement").length == 0 &&
            posts.filter(publicacion => publicacion.type === "resource-achievement").length == 0 
            ?
            <IonRow className="my-3">
              <IonCol size-md="4" size-xs="0" />
              <IonCol size-md="4" className="text-center d-flex flex-column align-items-center">
                <p>Empieza a publicar para compartir información e interactuar con los acompañantes del grupo.</p>
                <br />
                <IonImg className="empty-img pt-5" src={logoEmptyTimeline} style={{width: '85%'}} />
              </IonCol>
            </IonRow>
            : <>
              {/* MOSTRAR PUBLICACIONES DESTACADAS */}
              {showDestacados
                ?
                <>
                  {posts.filter(publicacion => publicacion.important === 1).map((publicacion, index) =>
                    <Fragment key={index}>{showPost(publicacion, index)}</Fragment>
                  )}
                </>
                : null}

              {/* MOSTRAR PUBLICACIONES DE OBJETIVOS */}
              {showObjetivos
                ?
                <>
                  {posts.filter(publicacion => publicacion.type === "objective-achievement").map((publicacion, index) =>
                    <Fragment key={index}>{showPost(publicacion, index)}</Fragment>
                    )}
                </>
                : null}

              {/* MOSTRAR TODAS LAS PUBLICACIONES */}
              {!showObjetivos && !showDestacados && refresh
                ?
                < >
                  {posts.map((publicacion, index) =>
                  <Fragment key={index}>
                    {index < postLimit &&
                      <Fragment key={index}> {publicacion.type === 'info'
                      ? <IonGrid className="mt-5">
                        <IonRow>
                          <IonCol size="8" offset="2" className="infoPost d-flex justify-content-center">
                            <p className={`body2 px-5`}>{publicacion.content}</p>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      : <>{showPost(publicacion, index)}</>
                    } </Fragment>}
                  </Fragment>
                  
                  )}
                  <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Cargando..."
                  ></IonInfiniteScrollContent>
                </>
                : null}
            </>}

        </div>

      </div>
    </>
  );
};
export default Home;



